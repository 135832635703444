.footer {
  height: 27.4375rem;
  max-height: 27.4375rem;
  width: 100vw;
  background-color: #353536;
  position: relative;
  margin-top: 5rem;
  overflow: hidden;
}

.address {
  position: absolute;
  right: 5rem;
  top: 50%;
  transform: translate(0%, -50%);
  text-align: right;
  color: white;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.address > *:first-child {
  font-size: 1.125rem;
  text-transform: uppercase;
}

.accent {
  width: 13.25rem;
  height: 0.0625rem;
  background-color: white;
  margin-top: 1rem;
}

.navIcon {
  background-color: #7cbbef;
  position: absolute;
  bottom: 26rem;
  right: 50%;
  transform: translate(50%, -0.75rem);
  border-radius: 5rem;
  height: 1.5rem;
  width: 1.5rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navIcon:hover {
  cursor: pointer;
}

.navIcon > * {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 10px;
}

.footerImgMobile {
  display: none;
}

@media screen and (max-width: 824px) {
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .address {
    right: 1rem;
  }

  .footer > img {
    padding: 2rem;
    padding-left: 0rem;
    height: calc(212px - 4rem);
  }

  .footerImgMobile {
    display: unset;
  }
  .footerImgDesktop {
    display: none;
  }
}

/* @media screen and (max-width: 515px) {
  .footer > img {
    padding: 0;
    height: 212px;
  }

  .address {
    transform: translate(20%, -50%);
  }
} */
