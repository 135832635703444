html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  overflow-y: auto;
}

.app {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

p {
  font-family: Roboto;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
  text-align: left;
}
