.card {
  padding: 1.75rem 1.75rem 5rem 1.75rem;
  background: #353536;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

*:has(> .card:nth-last-of-type(2)) .card {
  max-width: 38.25rem;
}

@media screen and (max-width: 824px) {
  .card {
    padding: 2rem 1rem;
  }
}
