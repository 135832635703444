.button {
  border: 0.1875rem solid white;
  background-color: unset;
  cursor: pointer;
  width: 13.125rem !important;
  height: 2.5rem !important;
}

.button:hover {
  background-color: white;
}

.invert {
  border: 0.125rem solid black;
}

.invert:hover {
  background-color: #353536;
}

.button span {
  font-size: 0.875rem !important;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  font-family: 'Roboto' !important;
}

.button:hover span {
  color: #353536;
}

.invert:hover span {
  color: white;
}
.invert span {
  color: black;
}
