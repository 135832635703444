.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 8.40625rem 0;
  max-width: 120rem;
  position: relative;
  row-gap: 3rem;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .section {
    margin: 8.40625rem 0;
  }
}
