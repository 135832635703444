.container {
  position: relative;
  height: 100svh;
  width: 100%;
  overflow: hidden;
  margin-top: 4rem;
}

.home {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.cTA {
  position: absolute;
  top: 66%;
  left: 5%;
  transform: translate(0, -50%);
  color: white;
  background-color: #35353680;
  width: 30.25rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.cTA .title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0rem;
  text-align: left;
  text-transform: uppercase;
}
.btnHolder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 824px) {
  .container {
    position: unset;
    height: unset;
    width: unset;
    overflow: unset;
    margin-top: unset;
    row-gap: unset;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home {
    height: unset;
    max-width: calc(100vw - 2rem);
  }

  .cTA {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    width: unset;
    padding: unset;

    color: #353534;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 1rem;
    max-width: calc(100vw - 2rem);
  }

  .cTA .title {
    font-weight: bold;
  }

  .title {
    display: flex;
    width: 100%;
    align-items: left;
  }

  .btnHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 2rem;
  }
  .btnHolder * {
    border-color: #353534 !important;
    color: #353534 !important;
    background-color: transparent !important;
  }
}
