.title {
  color: #353536;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
}

.accent {
  margin-top: 0.25rem;
  width: 2.0625rem;
  background-color: #4476f5;
  height: 0.0625rem;
  border: 0.0625rem solid #4476f5;
  border-radius: 1rem;
}

.alternate {
  color: white;
}

.large {
  font-size: 3rem;
  font-weight: 300;
}

.large .accent {
  width: 3.125rem;
}
