.aboutUs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 4.40625rem;
  column-gap: 8rem;
  color: #353536;
  padding: 4rem;
}

.imageHolder img {
  width: 31.25rem;
}

.textHolder {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.textHolder p {
  font-weight: 400;
  line-height: 1.61rem;
  letter-spacing: 0.05rem;
  text-align: left;
  max-width: 40.75rem;
  letter-spacing: 0rem;
}

@media screen and (max-width: 1224px) {
  .aboutUs {
    flex-direction: column;
  }
  .aboutUs img {
    max-width: 100vw;
    padding: none;
    margin: none;
    margin-bottom: 3.3rem;
  }
  .textHolder {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .aboutUs {
    padding: 1rem 2rem 0rem;
  }

  .aboutUs img {
    max-width: calc(100vw - 8rem);
  }
}

@media screen and (max-width: 425px) {
  .aboutUs {
    padding: 2rem 1rem;
  }
  .aboutUs img {
    max-width: calc(100vw - 4rem);
  }
}
