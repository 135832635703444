.oMContent {
  max-width: 59.125rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0.05rem;
  text-align: center;
  color: #353536;

}

.arrow {
  position: absolute;
  top: -8.40625rem;
  left: 50%;
  width: 0.5rem;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 768px) {
  .oMContent {
    padding: 4rem;
  }
}

@media screen and (max-width: 425px) {
  .oMContent {
    padding: 2rem;
  }
}
