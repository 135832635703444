.headerBanner {
  display: grid;
  grid-template-areas: 'links logo .';
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 5rem auto;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: transparent;
  transition: background-color 0.5s ease;
  min-height: 5rem;
}

.scrolled {
  background-color: rgba(0, 0, 0, 0.4);
}

.linkContainer {
  grid-area: links;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
  font-size: 1rem;
  color: white;
  padding-left: 4rem;
  text-transform: uppercase;
}

.linkContainer > *:hover {
  cursor: pointer;
}

.headerBanner a:has(img) {
  grid-area: logo;
  height: 3.75rem;
  padding: 0.5rem;
}

.backgroundTransition {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  opacity: 1;
  z-index: -1;
  transition: opacity 0.5s ease;
}

.scrolled .backgroundTransition {
  opacity: 0;
}

.menuBurger,
.burgerContainer {
  display: none;
}

@media screen and (max-width: 1024px) {
  .linkContainer {
    gap: 3rem;
  }
}
@media screen and (max-width: 824px) {
  .menuBurger {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    font-size: 1rem;
    color: white;
    padding-left: 2rem;
    text-transform: uppercase;
    justify-content: flex-start;
  }

  .menuBurger * {
    stroke: white;
  }

  .headerBanner {
    height: 5rem;
    transition: all 0.5s ease;
    padding-top: 0.5rem;
  }
  .headerBanner.menuExpand {
    background-color: #353534;
    height: 23.25rem;

    display: grid;
    grid-template-areas:
      'burger logo .'
      '. links .';
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 5rem auto;
  }

  .linkContainer {
    display: none;
  }

  .burgerContainer {
    grid-area: links;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: center;
    font-size: 1rem;
    color: white;
    margin-top: 2.5rem;
    text-transform: uppercase;
  }
}
