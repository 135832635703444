.careersAndServices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3rem;
  column-gap: 4rem;
  color: white;
  max-width: 82rem;
}

.careersAndServices img {
  width: 100%;
}

.title {
  grid-column: span 2;
  margin: 0 auto;
}

.title:not(:first-child) {
  margin-top: 8rem;
}

.careersAndServices > *:nth-child(5) {
  grid-column: span 2;
}

.cardGrid {
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  padding: 3.5rem 3.5rem 5rem 3.5rem;
  column-gap: 4rem;
  row-gap: 0;
}
.cardGrid img {
  overflow: hidden;
  width: 100%;
  grid-row: span 2;
  object-fit: cover;
}

.cardGrid p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 161%;
  letter-spacing: 0rem;
  max-width: 28.0625rem;
  text-align: left !important;
}

.careersAndServices p {
  text-align: center;
}

.iconRow {
  color: white;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;
  width: 100%;
  row-gap: 3rem;
  max-width: 68rem;
}

.iconRow > * {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0 auto;
  flex: 1;
  text-align: center;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.iconRow img {
  height: 1.25rem;
}

.iconRow span {
  text-wrap: nowrap;
}

@media screen and (max-width: 1312px) {
  .careersAndServices {
    grid-template-columns: 1fr;
  }
  .careersAndServices p {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
  }
  .careersAndServices button {
    margin-bottom: 4rem;
  }
  .careersAndServices > :nth-child(3) {
    margin-top: 3rem;
  }

  .title {
    grid-column: span 1;
  }

  .careersAndServices > *:nth-child(5) {
    grid-column: span 1;
  }
}
@media screen and (max-width: 1024px) {
  .cardGrid {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: unset;
    width: 100%;
  }
  .cardGrid > :first-child {
    padding: 2rem 0;
    font-size: 2rem;
    font-weight: 400;
  }
  .cardContainer p {
    margin: 0 auto;
    max-width: unset;
  }
  .cardGrid p {
    min-width: 100%;
  }
  .iconRow {
    margin-top: 1rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      'broker broker van van medal medal snow snow'
      '. tanker tanker trail trail flat flat .';
  }

  div:has(> img[alt='Brokerage Icon']) {
    grid-area: broker;
  }
  div:has(> img[alt='Van Icon']) {
    grid-area: van;
  }
  div:has(> img[alt='Tanker Icon']) {
    grid-area: tanker;
  }
  div:has(> img[alt='Snowflake Icon']) {
    grid-area: snow;
  }
  div:has(> img[alt='Medal Icon']) {
    grid-area: medal;
  }
  div:has(> img[alt='Trailer Icon']) {
    grid-area: trail;
  }
  div:has(> img[alt='Flatbed Icon']) {
    grid-area: flat;
  }
}

@media screen and (max-width: 768px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }
  .subtitle {
    display: flex;
    width: 100%;
    align-items: left;
  }

  .iconRow {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'broker broker van van tanker tanker'
      '. snow snow medal medal .'
      '. trail trail flat flat .';
  }
}

@media screen and (max-width: 350px) {
  .iconRow {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'broker van'
      'tanker snow'
      'medal trail'
      'flat flat';
  }
}
