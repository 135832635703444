.container {
  position: relative;
  height: 100svh;
  width: 100%;
  overflow: hidden;
}

.home {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.cTA {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrowEnd {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 5rem;
  width: 0.125rem;
  background-color: white;
  transform: translate(-50%, 0);
  animation: growShrink .9s infinite alternate;
  transform-origin: top center;
}

.scrolled {
  animation: unset;
}

@keyframes growShrink {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0.05);
  }
}
